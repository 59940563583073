import React, { useCallback, useState } from "react";

import { errorBoundary } from "../../../hocs";

import MainManualPresenter from "./main-manual.presenter";

// const HEIGHT = 600;
// const WIDTH = 800;

let MainManual: Function = () => {
  const [activeId, changeActiveId] = useState<string>("");

  // 튜토리얼 박스 클릭 시
  const onClickNode = useCallback((event, { id }) => {
    if (!id) return;
    changeActiveId(id);

    // const top = window.screen.height / 2 - HEIGHT / 2;
    // const left = window.screen.width / 2 - WIDTH / 2;

    if (id === "workflow") {
      return window.open(
        `https://www.youtube.com/watch?v=7zgvZxVzANI`,
        "Tutorial"
      );
    } else if (id === "library") {
      return window.open(
        `https://www.youtube.com/watch?v=H6EM39WzeEM`,
        "Tutorial"
      );
    } else if (id === "resource") {
      return window.open(
        `https://www.youtube.com/watch?v=HZ6yovFcPWM`,
        "Tutorial"
      );
    } else {
      return window.open(
        `https://www.youtube.com/watch?v=Y1H7T3noSaI`,
        "Tutorial"
      );
    }
  }, []);

  // 매뉴얼 > 바로가기 클릭 시
  const onClickGoGithub = useCallback(({ id }) => {
    if (id === "기본기능") {
      window.open(
        "https://gitlab.brique.kr/brique-analytics/manual/-/tree/v2.2.0-r1/01-%EB%A7%A4%EB%89%B4%EC%96%BC/02-%EC%82%AC%EC%9A%A9%EB%A7%A4%EB%89%B4%EC%96%BC/01.%EA%B8%B0%EB%B3%B8%EA%B8%B0%EB%8A%A5",
        "_blank"
      );
    } else if (id === "작성방법") {
      window.open(
        "https://gitlab.brique.kr/brique-analytics/manual/-/tree/v2.2.0-r1/01-%EB%A7%A4%EB%89%B4%EC%96%BC/02-%EC%82%AC%EC%9A%A9%EB%A7%A4%EB%89%B4%EC%96%BC/02.%EC%9E%91%EC%84%B1%EB%B0%A9%EB%B2%95",
        "_blank"
      );
    } else if (id === "기능활용") {
      window.open(
        "https://gitlab.brique.kr/brique-analytics/manual/-/tree/v2.2.0-r1/01-%EB%A7%A4%EB%89%B4%EC%96%BC/02-%EC%82%AC%EC%9A%A9%EB%A7%A4%EB%89%B4%EC%96%BC/03.%EA%B8%B0%EB%8A%A5%ED%99%9C%EC%9A%A9",
        "_blank"
      );
    } else if (id === "사용툴팁") {
      window.open(
        "https://gitlab.brique.kr/brique-analytics/manual/-/tree/v2.2.0-r1/01-%EB%A7%A4%EB%89%B4%EC%96%BC/02-%EC%82%AC%EC%9A%A9%EB%A7%A4%EB%89%B4%EC%96%BC/04.%EC%82%AC%EC%9A%A9%ED%88%B4%ED%8C%81",
        "_blank"
      );
    }
  }, []);
  // 매뉴얼 > 다운로드 클릭 시
  const onClickDownload = useCallback(({ id }) => {
    if (!id) return;
    window.location.href = `https://ba.brique.kr/file/${id}.zip`;
  }, []);

  return (
    <MainManualPresenter
      activeId={activeId}
      onClickNode={onClickNode}
      onClickGoGithub={onClickGoGithub}
      onClickDownload={onClickDownload}
    />
  );
};

MainManual = errorBoundary(MainManual);

export default MainManual;
