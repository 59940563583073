import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { EXAMPLE } from "../../../consts";

import IconPlus from "../../../assets/img/ic-plus.png";
import IconMinus from "../../../assets/img/ic-minus.png";
import IconCheck from "../../../assets/img/ic-check.png";

const Container = styled.div`
  height: auto;
  width: 100%;

  text-align: center;
  padding-top: 73px;
  padding-bottom: 137px;

  background-color: #fff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> List
const ListArea = styled.div`
  width: 1175px;

  margin: auto;
  padding: 100px 0 0;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 100px 80px 0;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 30px 20px 0;
  }
`;
const List = styled.div`
  width: 100%;

  margin-bottom: 23px;

  border-left: 6px solid #5185ff;
  border-bottom: 1px solid #cdd2d9;

  cursor: pointer;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-bottom: 10px;

    border-left: 4px solid #5185ff;
  }
`;
const TitleArea = styled.div`
  height: 54px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 28px;

  user-select: none;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    height: 36px;

    padding-left: 10px;
  }
`;
const ListTitle = styled.div`
  display: flex;
  align-items: center;

  width: calc(100% - 30px);

  font-family: "Noto Sans KR";
  font-weight: 400;
  color: #051441;
  letter-spacing: -1.04;
  font-size: 26px;
  white-space: nowrap;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 16px;
  }
`;
type StrongProps = {
  active: boolean;
};
const Strong = styled.div<StrongProps>`
  font-weight: 700;
  color: ${(props) => (props.active ? "#5587ff" : "#051441")};
`;
const SubText = styled.div`
  /* width: 100%; */

  font-size: 22px;
  letter-spacing: -0.88px;
  color: #5a5a5a;

  padding-left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 16px;
  font-family: "Noto Sans KR";
  letter-spacing: -0.64px;
  line-height: 1.56;
  color: #8b8b99;

  word-break: break-all;
  text-align: left;

  margin-top: 14px;
  margin-left: 26px;
  margin-bottom: 34px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
`;
const ContentTitleArea = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-bottom: 4px;
  }
`;
const IconBox = styled.div`
  width: 14px;
  height: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.72px;
  color: #5a5a5a;

  margin-left: 12px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Desc = styled.div`
  letter-spacing: -0.64px;
  line-height: 1.5;
  font-size: 16px;
  color: #8b8b99;

  margin-left: 26px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;

    padding-right: 20px;
  }
`;
// <<<

type ExampleProps = {
  items: any;
  onClickItem: Function;
};
const MainExample = ({ items, onClickItem }: ExampleProps) => {
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 576px)",
  });

  return (
    <Container>
      <SubTitle>BRIQUE Analytics Use Case</SubTitle>
      <Title>
        <Bold>BRIQUE Analytics</Bold> 활용사례
      </Title>

      <ListArea>
        {EXAMPLE.map((item) => {
          const active = items.get(item.id) && items.get(item.id).open;
          return (
            <List
              key={item.id}
              onClick={(event) => onClickItem(event, { id: item.id })}
            >
              <TitleArea>
                <ListTitle>
                  <Strong active={active}>{item.title}</Strong>
                  <SubText title={item.subTitle}>{item.subTitle}</SubText>
                </ListTitle>
                <img
                  src={active ? IconMinus : IconPlus}
                  alt="icon"
                  style={{ width: isMobile ? "11px" : "18px" }}
                />
              </TitleArea>

              {/* active 상태일 때 show */}
              {active && item.id === 1 ? (
                <>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>문제 인식</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      자재를 이미지화 하고 이를 육안으로 검사하여 불량 유무를
                      판단함
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>알고리즘 개발</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      이미지를 숫자로 변환하여, Spec을 정의하고 자동으로 이상
                      인지가 가능한 알고리즘을 개발
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>온라인 검증</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      분석가에 의해 개발된 알고리즘을
                      <br />
                      현업 담당자가 직접 BA를 활용하여, 속성 값을 변경해가며
                      검증을 수행함
                    </Desc>
                  </Content>
                </>
              ) : active && item.id === 2 ? (
                <>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>문제 인식</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      공정 진행과정마다 생성되는 이미지는 오류에 대한 유사한
                      패턴을 보이며, 이를 기반으로 원인 파악이 가능함
                      <br />
                      오류발생 원인에 대한 정보를 기반으로 공정을 제어할 수 있는
                      시스템을 필요로 함
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>알고리즘 개발</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      이미지 분할을 통해 인덱스를 생성하고, 검출 및 패턴 분류를
                      위한 알고리즘을 개발
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>온라인 검증</ContentTitle>
                    </ContentTitleArea>
                    <Desc>BA를 활용한 온라인 검증을 수행</Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>시스템 구축</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      공정 완료 메시지를 수신하여 알고리즘을 실행하고, 오류 발생
                      유무와 발생 원인에 대한 정보를 설정함
                      <br />
                      후속 공정 진행 시, 이 정보를 기반으로 공정 제어에 활용
                    </Desc>
                  </Content>
                </>
              ) : active && item.id === 3 ? (
                <>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>문제 인식</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      공정이 안정화 되기까지의 시간이나 처리횟수를 의미하는
                      Settling 조건을 반영한 Summary 결과를
                      <br />
                      지속적으로 비교하여, 최적의 Settling 조건을 찾기 위한
                      절차에 대한 자동화 필요
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>알고리즘 개발</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      다중 데이터의 수집을 위한 시스템 연계 및 Settling 유형에
                      따라 Summary(Min, Max, ..., Avg)를 산출하는 알고리즘 개발
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>온라인 검증</ContentTitle>
                    </ContentTitleArea>
                    <Desc>BA를 활용하여 검증을 수행</Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>배치 실행</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      시간 단위 반복적인 실행으로, 공정 및 처리 유형별 Summary
                      결과 추출
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>결과 분석</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      Summary 유형 별 결과 분석을 통해 최적의 Settling 조건 도출
                    </Desc>
                  </Content>
                </>
              ) : active && item.id === 4 ? (
                <>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>문제 인식</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      어플리케이션 서비스의 이상 유무 판단을 위한 H/W의 주기적인
                      이상 여부 점검은 지속적 관측이 필요한 업무로써,
                      <br />
                      점검 주기나 기준이 개개인 마다 다르기 때문에 문제의 인지
                      기준도 다를 수 있으며,
                      <br />
                      주기적인 점검 과정도 번거로움
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>알고리즘 개발</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      서비스 평균 수행속도를 누적하여 객관적인 지표로 표현하기
                      위한 알고리즘 개발
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>온라인 검증</ContentTitle>
                    </ContentTitleArea>
                    <Desc>BA를 활용하여 검증을 수행</Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>배치 실행</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      매일 반복적인 실행으로, 데이터 단순 조회속도의 증가/감소
                      추세지표 추출
                    </Desc>
                  </Content>
                  <Content>
                    <ContentTitleArea>
                      <IconBox>
                        <img src={IconCheck} alt="icon" />
                      </IconBox>
                      <ContentTitle>결과 통보</ContentTitle>
                    </ContentTitleArea>
                    <Desc>
                      매일 아침, 추출된 지표 및 결과 이미지를 Bot을 이용해서
                      메신저로 통보
                    </Desc>
                  </Content>
                </>
              ) : null}
            </List>
          );
        })}
      </ListArea>
    </Container>
  );
};

export default MainExample;
