import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { FAQ } from "../../../consts";

import BGImg from "../../../assets/img/faq-bg.png";
import IconPlus from "../../../assets/img/ic-plus.png";
import IconMinus from "../../../assets/img/ic-minus.png";

const Container = styled.div`
  height: auto;
  width: 100%;

  text-align: center;
  padding-top: 73px;
  padding-bottom: 137px;

  background-color: #ccc;
  background: url(${BGImg}) no-repeat center/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;
  padding: 0 20px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
    padding: 0;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> List
const ListArea = styled.div`
  width: 1175px;

  margin: auto;
  padding: 100px 0 0;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;

    padding: 100px 80px 0;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 30px 20px 0;
  }
`;
const List = styled.div`
  margin-bottom: 23px;

  border-left: 6px solid #5185ff;
  border-bottom: 1px solid #cdd2d9;

  cursor: pointer;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-bottom: 10px;

    border-left: 4px solid #5185ff;
  }
`;
const TitleArea = styled.div`
  height: 54px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 28px;

  user-select: none;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    height: 36px;

    padding-left: 10px;
  }
`;
type ListTitleProps = {
  active: boolean;
};
const ListTitle = styled.div<ListTitleProps>`
  font-family: "Noto Sans KR";
  font-weight: 400;
  letter-spacing: -1.04;
  font-size: 26px;
  color: ${(props) => (props.active ? "#5587ff" : "#051441")};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 16px;
  }
`;
const Strong = styled.span`
  font-weight: 700;
`;
const Content = styled.div`
  font-size: 16px;
  letter-spacing: -0.64px;
  line-height: 1.56;
  color: #8b8b99;

  word-break: break-all;
  text-align: left;

  margin-left: 26px;
  margin-bottom: 16px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;

    margin: 10px 0 22px 10px;
  }
`;
// <<<

type FAQProps = {
  items: any;
  onClickItem: Function;
};
const MainFAQ = ({ items, onClickItem }: FAQProps) => {
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 576px)",
  });

  return (
    <Container>
      <SubTitle>Frequently Asked Questions</SubTitle>
      <Title>
        <Bold>BRIQUE Analytics</Bold>의 자주 묻는 질문들
      </Title>

      <ListArea>
        {FAQ.map((item) => {
          const active = items.get(item.id) && items.get(item.id).open;
          return (
            <List
              key={item.id}
              onClick={(event) => onClickItem(event, { id: item.id })}
            >
              <TitleArea>
                <div
                  style={{
                    height: "100%",
                    width: "calc(100% - 18px)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ListTitle active={active}>
                    <Strong>{item.titleBold}</Strong>
                    {item.title}
                  </ListTitle>
                </div>
                <img
                  src={active ? IconMinus : IconPlus}
                  alt="icon"
                  style={{ width: isMobile ? "11px" : "18px" }}
                />
              </TitleArea>

              {/* active 상태일 때 show */}
              {active && item.id === 1 ? (
                <Content>
                  <p style={{ marginBottom: "14px" }}>
                    데이터 분석은 비즈니스의 성과를 얻기 위해
                    <br />
                    분석결과를 어디에 활용할지를 생각하고
                    <br />
                    분석목적을 명확히 설정한 뒤<br />그 분석목적의 달성을 위해
                    알고 싶은 문제를 구체화하고 해답을 찾아가는 일련된
                    과정입니다
                  </p>
                  때문에 분석목적을 명확히 설정하는 것이 무엇보다 중요하며,
                  <br />
                  분석목적은 예측, 판단, 효과검정, 자동화, 최적화, 추세예측
                  등으로 분류될 수 있습니다
                </Content>
              ) : active && item.id === 2 ? (
                <Content>
                  데이터 분석을 위한 수집, 전처리, 모델링, 결과시각화 등의
                  과정에서 사용되는 필수요소 알고리즘으로써
                  <br />
                  분석목적 달성을 위한 워크플로우(Workflow)를 구성하는데
                  사용되어집니다
                </Content>
              ) : active && item.id === 3 ? (
                <Content>
                  데이터 분석을 위한 일련의 과정을 표현하는 흐름의 단위로써,
                  라이브러리들의 조합으로 구성됩니다.
                </Content>
              ) : active && item.id === 4 ? (
                <Content>
                  리소스(Resource)란 데이터 분석에 사용되는 자원으로써,
                  <br />
                  분석가의 로컬 PC 내에 존재하는 텍스트 및 이미지 파일이나
                  데이터베이스에 존재하는 다양한 형태의 데이터를 의미합니다.
                  <br />
                  분석 알고리즘 수행에 필요한 이러한 데이터들은 수행 전에 BRIQUE
                  Analytics의 리소스 형태로 등록되어야만 사용할 수 있으며,
                  <br />
                  파일의 경우, 업로드 기능을 이용해서 리소스로 등록할 수 있고,
                  <br />
                  데이터베이스에 존재하는 데이터의 경우, DB 커넥션 정보를
                  이용하여 리소스로 등록할 수 있습니다
                </Content>
              ) : null}
            </List>
          );
        })}
      </ListArea>
    </Container>
  );
};

export default MainFAQ;
