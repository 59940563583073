import styled from "styled-components";

type AdditionalProps = {
  width: string;
  height: string;
  lineHeight: string;
  margin: string;
  fontSize: string;
  borderRadius: string;
  border: string;
  fontFamily: string;
  hoverBgColor: string;
  hoverColor: string;
};
const ButtonWhite = styled.div.attrs<AdditionalProps>(
  ({
    width,
    height,
    lineHeight,
    margin,
    fontSize,
    borderRadius,
    border,
    fontFamily,
    hoverBgColor,
    hoverColor,
  }) => ({
    style: {
      width,
      height,
      lineHeight,
      margin,
      fontSize,
      borderRadius,
      border,
      fontFamily,
    },
  })
)`
  font-family: "Noto Sans KR", "Roboto";
  font-weight: 500;
  color: #5185ff;

  display: inline-block;

  border-radius: 24px;

  text-align: center;

  background-color: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #5185ff;
    /* background-color: hoverColor; */
    /* border: 1px solid hoverColor; */
    border: 1px solid #5185ff;
    color: #fff;
    /* color: hoverBgColor; */
  }
`;

export default ButtonWhite;
