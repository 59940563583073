export const INSTALL_STANDALONE = [
  {
    key: 1,
    title: "Operating System - Linux",
    desc: [
      {
        id: 1,
        text: "CentOS 7 / 8 이상",
      },
      {
        id: 2,
        text: "Fedora 32 이상",
      },
      {
        id: 3,
        text: "RedHat 7 이상",
      },
      {
        id: 4,
        text: "Debian Buster 10 (Stable) 이상",
      },
      {
        id: 5,
        text: "Ubuntu 18.04, 20.04 이상",
      },
    ],
  },
  {
    key: 2,
    title: "Operating System - Windows",
    desc: [
      {
        id: 1,
        title: "Windows 10 64-bit",
        text: "Home or Pro 2004 (build 19041) 이상",
      },
      {
        id: 2,
        text: "Enterprise or Education 1909 (build 18363) 이상",
      },
      {
        id: 3,
        title: "Windows 11 64-bit",
        text: "Home or Pro version 21H2 이상",
      },
      {
        id: 4,
        text: "Enterprise or Education 21H2 이상",
      },
    ],
  },
  {
    key: 3,
    title: "Required Software",
    desc: [
      {
        id: 1,
        text: "Docker 19.03 이상",
      },
    ],
  },
  {
    key: 4,
    title: "CPU",
    desc: [
      {
        id: 1,
        text: "x64 4 Core 이상",
      },
    ],
  },
  {
    key: 5,
    title: "Memory",
    desc: [
      {
        id: 1,
        text: "16GB 이상",
      },
    ],
  },
  {
    key: 6,
    title: "HDD",
    desc: [
      {
        id: 1,
        text: "50GB 이상",
      },
    ],
  },
];

export const INSTALL_ENTERPRISE = [
  {
    key: 1,
    title: "Operating System",
    desc: [
      {
        id: 1,
        text: "CentOS 7.x 이상",
      },
    ],
  },
  {
    key: 2,
    title: "Required Software",
    desc: [
      {
        id: 1,
        text: "docker 19.x 이상",
      },
    ],
  },
  {
    key: 3,
    title: "CPU",
    desc: [
      {
        id: 1,
        text: "최소 Intel xeon 2.5GHz 12core X 1cpu(24core X 2cpu 권장)",
      },
    ],
  },

  {
    key: 4,
    title: "Memory",
    desc: [
      {
        id: 1,
        text: "최소 128GB 이상 (512GB 권장)",
      },
    ],
  },

  {
    key: 5,
    title: "HDD",
    desc: [
      {
        id: 1,
        text: "최소 512GB 이상 (SSD 1TB 권장)",
      },
    ],
  },

  {
    key: 6,
    title: "Ecosystem",
    desc: [
      {
        id: 1,
        text: "NAS(Network Attached Storage)",
      },
      {
        id: 2,
        text: "(Optional) L4 Switching for Load Balancing Server",
      },
      {
        id: 3,
        text: "#1 for Ecosystem Server ",
      },
      {
        id: 4,
        text: "#2 for Platform Server",
      },
      {
        id: 5,
        text: "#3 for Platform Server",
      },
      {
        id: 6,
        text: "#4 (Optional) for Batch Server",
      },
      {
        id: 7,
        text: "#5 (Optional) for GPU  Server",
      },
      {
        id: 8,
        text: "#6 (Optional) for GPU ",
      },
    ],
  },
];
