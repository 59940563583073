import React, { useCallback, useEffect, useState } from "react";

import { errorBoundary } from "../../../hocs";
import { MENU } from "../../../consts";
import { services } from "../../../services";

import MainHeaderPresenter from "./main-header.presenter";

let MainHeader: Function = () => {
  const [menus, changeMenus] = useState(new Map());
  const [isOpenMenu, changeIsOpenMenu] = useState(false);
  const [menuHoverId, changeMenuHoverId] = useState("");

  // menu 항목 init set
  useEffect(() => {
    const init = new Map();
    MENU.map((item) => init.set(item.id, item));
    changeMenus(init);
  }, []);

  // 햄버거 메뉴 아이콘 클릭 시,
  const onClickMenuIcon = useCallback((e) => changeIsOpenMenu(!isOpenMenu), [
    isOpenMenu,
  ]);

  // menu에 마우스 호버 시 (컬러변경을 위한 state)
  const onMouseOverMenu = useCallback((id) => changeMenuHoverId(id), []);
  // menu에 마우스 아웃 시
  const onMouseLeaveMenu = useCallback((id) => changeMenuHoverId(id), []);

  // 메뉴창이 닫힐 때, 열려있던 모든 collapse 닫음
  const closeAllCollapse = useCallback(() => {
    const set = new Map(menus);
    for (const [key, item] of menus) {
      set.set(key, { ...item, open: false });
    }
    changeMenus(set);
  }, [menus]);

  // 메뉴 클릭 시
  const onClickMenu = useCallback(
    (id) => {
      services.currentMenuId(id);

      const set = new Map(menus);
      for (const [key, item] of menus) {
        // download, link 클릭 시, open 상태 초기화
        if (id === "Download") {
          changeIsOpenMenu(false);
          changeMenuHoverId("");
          closeAllCollapse();
          return;
        } else if (id === "Link") {
          changeIsOpenMenu(false);
          changeMenuHoverId("");
          closeAllCollapse();
          return;
        } else if (key === id) {
          set.set(key, { ...item, open: !menus.get(key).open });
        } else {
          set.set(key, { ...item, open: false });
        }
      }
      changeMenus(set);
    },
    [menus, closeAllCollapse]
  );

  // 하위 메뉴 클릭 시 (모든 collapse닫음, 메뉴 영역 비활성)
  const onClickSubMenu = useCallback(
    (id) => {
      services.currentMenuId(id);

      const set = new Map(menus);
      for (const [key, item] of menus) {
        set.set(key, { ...item, open: false });
      }
      changeMenus(set);
      changeIsOpenMenu(false);
    },
    [menus]
  );

  return (
    <MainHeaderPresenter
      menus={menus}
      isOpenMenu={isOpenMenu}
      onClickMenuIcon={onClickMenuIcon}
      onClickMenu={onClickMenu}
      onClickSubMenu={onClickSubMenu}
      menuHoverId={menuHoverId}
      onMouseOverMenu={onMouseOverMenu}
      onMouseLeaveMenu={onMouseLeaveMenu}
    />
  );
};

MainHeader = errorBoundary(MainHeader);

export default MainHeader;
