export const FAQ = [
  {
    id: 1,
    titleBold: "데이터분석 목적",
    title: "에는 어떤 것들이 있나요?",
    open: false,
  },
  {
    id: 2,
    titleBold: "라이브러리(Library)",
    title: "란 무엇인가요?",
    open: false,
  },
  {
    id: 3,
    titleBold: "워크플로우(Workflow)",
    title: "는 무엇인가요?",
    open: false,
  },
  {
    id: 4,
    titleBold: "리소스(Resource)",
    title: "는 무엇인가요?",
    open: false,
  },
];
