import styled from "styled-components";

type AdditionalProps = {
  width: string;
  height: string;
  lineHeight: string;
  margin: string;
  fontSize: string;
};
const ButtonBlack = styled.div.attrs<AdditionalProps>(
  ({ width, height, lineHeight, margin, fontSize }) => ({
    style: {
      width,
      height,
      lineHeight,
      margin,
      fontSize,
    },
  })
)`
  line-height: 50px;

  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  color: #fff;

  border-radius: 24px;

  text-align: center;

  background-color: #383838;

  cursor: pointer;
  user-select: none;
`;

export default ButtonBlack;
