import React from "react";
import styled from "styled-components";

import { MENU, INTRODUCTION_MENU, MANUAL_MENU } from "../../../consts";

import IcMenuOpenBlack from "../../../assets/img/ic-menu-open-black.svg";
import IcMenuClose from "../../../assets/img/ic-menu-close.png";
import IcIntroduction from "../../../assets/img/ic-introduction.svg";
import IcManual from "../../../assets/img/ic-manual.svg";
import IcLink from "../../../assets/img/ic-link.svg";
import IcDownload from "../../../assets/img/ic-download.svg";
import IcCollapseOpen from "../../../assets/img/ic-collapse-open.svg";
import IcCollapseCloseBlue from "../../../assets/img/ic-collapse-close-blue.png";

type ContainerProps = {
  isOpenMenu: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  /* Desktop */
  @media only screen and (min-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 30;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    position: fixed;
    top: 0;
    width: ${(props) => props.isOpenMenu && "100%"};
    height: ${(props) => props.isOpenMenu && "100%"};
    z-index: 30;
  }
`;
// >>> 메뉴 icon
const MenuOpenArea = styled.div`
  position: absolute;
  top: 55px;
  left: 57px;

  cursor: pointer;

  @media only screen and (min-width: 320px) and (max-width: 720px) {
    top: 30px;
    left: 20px;
  }
`;
const MenuCloseArea = styled.div`
  position: absolute;
  top: 55px;
  left: 57px;

  cursor: pointer;

  @media only screen and (min-width: 320px) and (max-width: 720px) {
    top: 30px;
    left: 20px;
  }
`;
// >>> show menu
const ShowMenuArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 497px;
  height: 1190px;

  background-color: rgba(0, 0, 0, 0.7);
  user-select: none;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 80%;
    height: 100%;

    background-color: #243e77;
  }
`;
const MenuList = styled.div`
  margin-top: 142px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-top: 80px;
  }
`;
type MenuProps = {
  active: boolean;
  menuHoverId: boolean;
};
const Menu = styled.div<MenuProps>`
  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) =>
    props.active ? "rgba(32, 32, 32, 0.733)" : "transparent"};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.menuHoverId || props.active ? "#7aabff" : "#fff")};

  border-bottom: 1px solid rgba(148, 148, 148, 0.3);

  padding-left: 60px;
  cursor: pointer;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    height: 60px;

    font-size: 18px;
    font-weight: 400;

    padding-left: 20px;
  }
`;
const ArrowArea = styled.div`
  padding-right: 60px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-right: 20px;
  }
`;
// >>> content : sub menu area
const ContentArea = styled.div`
  padding: 20px 0;

  background-color: rgba(32, 32, 32, 0.8);

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 10px 0;
  }
`;
const SubMenu = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;

  padding-left: 90px;
  cursor: pointer;

  &:hover {
    color: #7aabff;
  }

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
    font-weight: 400;
    padding-left: 50px;
  }
`;

type MainHeaderProps = {
  menus: any;
  isOpenMenu: boolean;
  onClickMenuIcon: any;
  onClickMenu: any;
  onClickSubMenu: any;

  menuHoverId: string;
  onMouseOverMenu: any;
  onMouseLeaveMenu: any;
};
const MainHeader = ({
  menus,
  isOpenMenu,
  onClickMenuIcon,
  onClickMenu,
  onClickSubMenu,

  menuHoverId,
  onMouseOverMenu,
  onMouseLeaveMenu,
}: MainHeaderProps) => {
  return (
    <Container isOpenMenu={isOpenMenu}>
      <MenuOpenArea onClick={onClickMenuIcon}>
        {!isOpenMenu ? <img src={IcMenuOpenBlack} alt="menu open" /> : null}
      </MenuOpenArea>
      {isOpenMenu && (
        <ShowMenuArea>
          <MenuCloseArea onClick={onClickMenuIcon}>
            <img src={IcMenuClose} alt="menu close" />
          </MenuCloseArea>

          <MenuList>
            {MENU.map((item) => {
              const active = menus.get(item.id) && menus.get(item.id).open;
              const icon =
                item.id === "Introduction"
                  ? IcIntroduction
                  : item.id === "Manual"
                  ? IcManual
                  : item.id === "Link"
                  ? IcLink
                  : item.id === "Download"
                  ? IcDownload
                  : IcIntroduction;
              return (
                <div key={item.id}>
                  <Menu
                    active={active}
                    menuHoverId={
                      menuHoverId && menuHoverId === item.id ? true : false
                    }
                    onClick={() => onClickMenu(item.id)}
                    onMouseOver={() => onMouseOverMenu(item.id)}
                    onMouseLeave={() => onMouseLeaveMenu(item.id)}
                  >
                    <div>
                      <img
                        src={icon}
                        alt="menu icon"
                        style={{ marginRight: "12px", marginTop: "1px" }}
                      />
                      {item.id}
                    </div>
                    <ArrowArea>
                      <img
                        src={active ? IcCollapseCloseBlue : IcCollapseOpen}
                        alt="menu open"
                        // style={{ paddingRight: "60px" }}
                      />
                    </ArrowArea>
                  </Menu>
                  {active && item.id === "Introduction" && (
                    <ContentArea>
                      {INTRODUCTION_MENU.map((item) => (
                        <SubMenu
                          key={item.id}
                          onClick={() => onClickSubMenu(item.id)}
                        >
                          {item.name}
                        </SubMenu>
                      ))}
                    </ContentArea>
                  )}
                  {active && item.id === "Manual" && (
                    <ContentArea>
                      {MANUAL_MENU.map((item) => (
                        <SubMenu
                          key={item.id}
                          onClick={() => onClickSubMenu(item.id)}
                        >
                          {item.name}
                        </SubMenu>
                      ))}
                    </ContentArea>
                  )}
                </div>
              );
            })}
          </MenuList>
        </ShowMenuArea>
      )}
    </Container>
  );
};

export default MainHeader;
