import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";

import { Desktop, Mobile } from "../../../consts";

import BGImg from "../../../assets/img/about-bg.png";
import IconAlgorithm from "../../../assets/img/ic-algorithm.png";
import IconPython from "../../../assets/img/ic-python.png";
import IconRestAPI from "../../../assets/img/ic-restapi.png";
import IconSeperate from "../../../assets/img/ic-seperate.png";

SwiperCore.use([Navigation, Pagination]);

const Container = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  padding-top: 72px;
  padding-bottom: 110px;

  background: url(${BGImg}) no-repeat center/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    height: auto;

    padding-top: 40px;
    padding-bottom: 30px;

    background: #fff;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Tablet */
  @media only screen and (min-width: 577px) and (max-width: 1200px) {
    padding: 0 20px;
    margin-bottom: 40px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> node
const NodeArea = styled.div`
  position: relative;

  margin-top: 100px;

  /* Tablet */
  @media only screen and (min-width: 577px) and (max-width: 1200px) {
    width: 100%;
    padding: 0 80px;
  }
`;
const NodeLayout = styled.div`
  width: 972px;

  display: flex;
  justify-content: space-between;

  /* Tablet */
  @media only screen and (min-width: 577px) and (max-width: 1200px) {
    width: 100%;

    &:nth-child(1) {
      margin-bottom: 2%;
    }
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100%;
  }
`;
type NodeBoxStyle = {
  height?: string;
  margin?: string;
};
const NodeBox = styled.div<NodeBoxStyle>`
  width: 371px;
  height: ${(props) => props.height};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 5px solid #5185ff;
  background-color: #fff;
  box-shadow: 0px 2px 5px 2px rgba(240, 240, 240, 1);

  margin: ${(props) => props.margin};

  /* Tablet */
  @media only screen and (min-width: 577px) and (max-width: 1200px) {
    height: 370px;
    width: 49%;

    margin: 0;

    &:nth-child(1) {
      margin-right: 2%;
      margin: auto;
    }
  }
  @media only screen and (max-width: 577px) {
    width: 65%;
    margin: 20px auto 0;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 65%;
    height: 260px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 20px auto 0;
  }
`;
type NodeTitleStyle = {
  margin: string;
};
const NodeTitle = styled.h3<NodeTitleStyle>`
  font-family: "Noto Sans KR";
  font-size: 22px;
  font-weight: 900;
  color: #0c0b56;

  margin: ${(props) => props.margin};

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 16px;
  }
`;
const Desc = styled.p`
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
  letter-spacing: -0.72px;
  color: #5a5a5a;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 12px;
    line-height: 1.6;
  }
`;
const Strong = styled.strong`
  font-weight: 700;
`;

const MainAbout = () => {
  const isTablet = useMediaQuery({
    query: "(min-width: 576px) and (max-width: 1200px)",
  });

  return (
    <Container className="about">
      <SubTitle>What is the BRIQUE Analytics?</SubTitle>
      {/* Desktop */}
      <Desktop>
        {isTablet ? (
          <Title>
            <Bold>BRIQUE Analytics</Bold>는 쉽고 편리한
            <br /> 데이터 분석환경을 제공하는
            <br /> 통합분석 플랫폼 입니다.
          </Title>
        ) : (
          <Title>
            <Bold>BRIQUE Analytics</Bold>는 쉽고 편리한 데이터 분석환경을
            <br />
            제공하는 통합분석 플랫폼 입니다.
          </Title>
        )}

        <NodeArea>
          <NodeLayout>
            {/* 알고리즘 작성도구 */}
            <NodeBox height="332px" margin="0">
              <img src={IconAlgorithm} alt="icon" />
              <NodeTitle margin="30px 0 19px 0">알고리즘 작성도구</NodeTitle>
              <Desc>
                웹을 통해 분석 알고리즘을 작성할 수 있는
                <br />
                <Strong>그래픽 워크플로우</Strong>를 네이티브 어플리케이션
                <br />
                UX패턴으로 제공
              </Desc>
            </NodeBox>

            {/* 이종언어 실행엔진 */}
            <NodeBox height="365px" margin="28px 0 0 0">
              <img src={IconPython} alt="icon" />
              <NodeTitle margin="29px 0 24px 0">이종언어 실행엔진</NodeTitle>
              <Desc style={{ marginBottom: "20px" }}>
                <Strong>R 또는 Python</Strong>으로 작성된 스크립트를
                <br />
                언어유형에 따라 동적으로 실행가능
              </Desc>
              <Desc>
                분석 워크플로우의 알고리즘 노드를
                <br />
                <Strong>독립 프로세스</Strong>로 실행하여 독립성 보장
              </Desc>
            </NodeBox>
          </NodeLayout>
          <NodeLayout>
            {/* REST API */}
            <NodeBox height="305px" margin="0">
              <img src={IconRestAPI} alt="icon" />
              <NodeTitle margin="30px 0 21px 0">REST API</NodeTitle>
              <Desc>
                <Strong>REST API</Strong>기반의 서비스 제공으로
                <br />타 시스템 서비스와의 손쉬운 연계
              </Desc>
            </NodeBox>

            {/* 분산처리 시스템 */}
            <NodeBox height="339px" margin="74px 0 0 0">
              <img src={IconSeperate} alt="icon" />
              <NodeTitle margin="28px 0 20px 0">분산처리 시스템</NodeTitle>
              <Desc>
                경량 컨테이너 가상화 기술, 클러스터링
                <br />및 오케스트레이션 툴을 활용한 <Strong>아키텍처</Strong>
                <br />
                <Strong>설계</Strong>로 오프라인 및 온라인 기반의
                <br />
                분석처리 성능 확보
              </Desc>
            </NodeBox>
          </NodeLayout>

          {/* Path */}
          {isTablet ? null : (
            <svg
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                userSelect: "none",
              }}
            >
              {/* path: 알고리즘 - 이종언어 */}
              <circle cx="371" cy="246" r="5" fill="#5185ff" />
              <circle cx="600" cy="84" r="5" fill="#5185ff" />
              <path
                d={`M371 246 Q 440 230, 480 160 T 600 84`}
                fill="transparent"
                stroke="#5185ff"
              />
              {/* path: 알고리즘 - 분산처리 */}
              <circle cx="600" cy="708" r="5" fill="#5185ff" />
              <path
                d={`M371 246 Q 458 290, 478 470 T 600 710`}
                fill="transparent"
                stroke="#5185ff"
              />
              {/* path: restAPI - 분산처리 */}
              <circle cx="371" cy="548" r="5" fill="#5185ff" />
              <path
                d={`M371 548 Q 440 550, 490 630 T 600 710`}
                fill="transparent"
                stroke="#5185ff"
              />
            </svg>
          )}
        </NodeArea>
      </Desktop>

      {/* Mobile */}
      <Mobile>
        <Title>
          <Bold>BRIQUE Analytics</Bold>는 쉽고 편리한
          <br />
          데이터 분석환경을 제공하는
          <br />
          통합분석 플랫폼 입니다.
        </Title>
        {/* slider */}
        <Swiper
          tag="section"
          wrapperTag="ul"
          pagination
          spaceBetween={0}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide tag="li">
            {/* 알고리즘 작성도구 */}
            <NodeBox>
              <img src={IconAlgorithm} alt="icon" style={{ width: "44px" }} />
              <NodeTitle margin="24px 0 19px 0">알고리즘 작성도구</NodeTitle>
              <Desc>
                웹을 통해 분석 알고리즘을
                <br /> 작성할 수 있는
                <Strong>그래픽 워크플로우</Strong>를<br /> 네이티브 어플리케이션
                <br />
                UX패턴으로 제공
              </Desc>
            </NodeBox>
          </SwiperSlide>
          <SwiperSlide tag="li">
            {/* 이종언어 실행엔진 */}
            <NodeBox>
              <img src={IconPython} alt="icon" style={{ width: "44px" }} />
              <NodeTitle margin="24px 0 24px 0">이종언어 실행엔진</NodeTitle>
              <Desc style={{ marginBottom: "16px" }}>
                <Strong>R 또는 Python</Strong>으로 작성된 스크립트를
                <br />
                언어유형에 따라 동적으로 실행가능
              </Desc>
              <Desc>
                분석 워크플로우의 알고리즘 노드를
                <br />
                <Strong>독립 프로세스</Strong>로 실행하여 독립성 보장
              </Desc>
            </NodeBox>
          </SwiperSlide>
          <SwiperSlide tag="li">
            {/* REST API */}
            <NodeBox>
              <img src={IconRestAPI} alt="icon" style={{ width: "44px" }} />
              <NodeTitle margin="24px 0 21px 0">REST API</NodeTitle>
              <Desc>
                <Strong>REST API</Strong>기반의 서비스 제공으로
                <br />타 시스템 서비스와의 손쉬운 연계
              </Desc>
            </NodeBox>
          </SwiperSlide>
          <SwiperSlide tag="li">
            {/* 분산처리 시스템 */}
            <NodeBox>
              <img src={IconSeperate} alt="icon" style={{ width: "44px" }} />
              <NodeTitle margin="24px 0 20px 0">분산처리 시스템</NodeTitle>
              <Desc>
                경량 컨테이너 가상화 기술, 클러스터링
                <br />및 오케스트레이션 툴을 활용한
                <br /> <Strong>아키텍처</Strong>
                <Strong>설계</Strong>로 오프라인 및 온라인
                <br /> 기반의 분석처리 성능 확보
              </Desc>
            </NodeBox>
          </SwiperSlide>
        </Swiper>
      </Mobile>
    </Container>
  );
};

export default MainAbout;
