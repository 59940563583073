// 메인메뉴
export const MENU = [
  {
    id: "Introduction",
    path: "/introduction",
    name: "Introduction",
    open: false,
  },
  {
    id: "Manual",
    path: "/manual",
    name: "Manual",
    open: false,
  },
  {
    id: "Link",
    path: "/link",
    name: "Link",
    open: false,
  },
  {
    id: "Download",
    path: "/download",
    name: "Download",
    open: false,
  },
];

// 서브메뉴
export const INTRODUCTION_MENU = [
  {
    id: "About",
    name: "ABOUT",
  },
  {
    id: "FAQ",
    name: "FAQ",
  },
  {
    id: "활용사례",
    name: "활용사례",
  },
];

export const MANUAL_MENU = [
  {
    id: "기본기능",
    name: "기본기능",
  },
  {
    id: "작성방법",
    name: "작성방법",
  },
  {
    id: "기능활용",
    name: "기능활용",
  },
  {
    id: "사용툴팁",
    name: "사용툴팁",
  },
  {
    id: "튜토리얼",
    name: "튜토리얼",
  },
];
