import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Desktop, Mobile } from "../../../consts";

import BGImg from "../../../assets/img/effect-bg.png";
import BAImg from "../../../assets/img/effect-ba.png";
import Img2 from "../../../assets/img/img-effect-2.png";

const Container = styled.div`
  /* height: 1891px; */
  height: auto;
  width: 100%;

  text-align: center;
  padding-top: 73px;
  padding-bottom: 135px;

  background: url(${BGImg}) no-repeat center/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;

    background: #fff;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Description = styled.div`
  font-family: "Noto Sans KR";
  font-size: 22px;
  color: #5a5a5a;
  line-height: 1.45;
  letter-spacing: -0.8px;

  margin-top: 191px;
  margin-bottom: 87px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 20px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
const Strong = styled.strong`
  font-weight: 700;
  color: #5587ff;
`;
// <<<
// >>> img
const ImgArea = styled.div`
  width: 1170px;

  display: inline-block;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;

    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 0 20px;
  }
`;

const MainEffect = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  return (
    <Container>
      <SubTitle>What is the benefit?</SubTitle>
      <Title>
        <Desktop>
          <Bold>BRIQUE Analytics</Bold>의 도입 효과는 무엇일까요?
        </Desktop>
        <Mobile>
          <Bold>BRIQUE Analytics</Bold>의<br /> 도입 효과는 무엇일까요?
        </Mobile>
      </Title>
      <ImgArea>
        <img
          src={BAImg}
          alt=""
          style={{ width: "100%", marginTop: isMobile ? "20px" : "81px" }}
        />
      </ImgArea>
      <Desktop>
        <Description>
          BRIQUE Analytics를 기반으로 문제 해결을 위해 도출된 개념을 검증하고
          <br />
          시스템화 할 수 있는 <Strong>통합 분석 환경을 실현</Strong>할 수
          있습니다.
        </Description>
      </Desktop>
      <Mobile>
        <Description>
          BRIQUE Analytics를 기반으로 문제
          <br /> 해결을 위해 도출된 개념을 검증하고
          <br /> 시스템화 할 수 있는
          <Strong>
            통합 분석
            <br /> 환경을 실현
          </Strong>
          할 수 있습니다.
        </Description>
      </Mobile>
      <ImgArea>
        <img src={Img2} alt="" style={{ width: "100%" }} />
      </ImgArea>
    </Container>
  );
};

export default MainEffect;
