import React from "react";
import styled from "styled-components";

import BGImg from "../../../assets/img/contact-bg.png";
import IconEmail from "../../../assets/img/ic-email.png";

const Container = styled.div`
  height: auto;
  width: 100%;

  text-align: center;
  padding-top: 73px;
  padding-bottom: 92px;

  background: url(${BGImg}) no-repeat bottom/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #fff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #fff;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    padding: 0 20px;
    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> contact
const ContactArea = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 60px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-top: 20px;
  }
`;
const ContactBox = styled.div`
  width: 424px;
  height: 57px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Roboto";
  font-size: 22px;
  font-weight: 500;

  background-color: #fff;
  color: #5185fe;

  cursor: pointer;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 60%;
    height: 40px;

    font-size: 14px;

    margin: 0 20px;
  }
`;
// <<<

const MainContact = () => {
  return (
    <Container>
      <SubTitle>BRIQUE Analytics Contact</SubTitle>
      <Title>
        <Bold>BRIQUE Analytics</Bold>에 무엇이든 문의하세요
      </Title>

      <ContactArea>
        <ContactBox
          style={{ marginRight: "19px" }}
          onClick={() => window.open("mailto:admin@brique.co.kr")}
        >
          <img
            src={IconEmail}
            alt="email icon"
            style={{ marginRight: "6px", marginTop: "2px" }}
          />
          admin@brique.co.kr
        </ContactBox>
      </ContactArea>
    </Container>
  );
};

export default MainContact;
