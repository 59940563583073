import React, { useCallback } from "react";

import { errorBoundary } from "../../../hocs";
import { services } from "../../../services";

import MainTitlePresenter from "./main-title.presenter";

let MainTitle: Function = () => {
  const onClickDownload = useCallback(
    () => services.currentMenuId("Download"),
    []
  );

  const onClickCatalog = useCallback(
    () => window.open("https://ba.brique.kr/file/05-BA-Brochure.pdf", "_blank"),
    []
  );

  return (
    <MainTitlePresenter
      onClickDownload={onClickDownload}
      onClickCatalog={onClickCatalog}
    />
  );
};

MainTitle = errorBoundary(MainTitle);

export default MainTitle;
