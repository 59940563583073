import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "../../../consts";

import BGImg from "../../../assets/img/needs-bg.png";
import IconAlgorithm from "../../../assets/img/ic-needs-algorithm.png";
import IconPython from "../../../assets/img/ic-needs-python.png";
import IconCloud from "../../../assets/img/ic-needs-cloud.png";
import IconScenario from "../../../assets/img/ic-needs-scenario.png";

const Container = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-top: 75px;

  background: url(${BGImg}) no-repeat bottom/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> node
const BoxArea = styled.div`
  display: flex;

  margin-top: 78px;
  margin-bottom: 194px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;

    flex-flow: wrap;

    margin-bottom: 75px;
    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0 20px;
  }
`;
type NodeBoxStyle = {
  margin: string;
};
const NodeBox = styled.div<NodeBoxStyle>`
  width: 270px;
  height: 313px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 5px solid #5185ff;
  background-color: #fff;
  box-shadow: 0px 2px 5px 4px rgba(194, 194, 194, 0.3);

  margin: ${(props) => props.margin};

  /* Under 1200px - Mobile */
  @media only screen and (max-width: 1200px) {
    width: 49%;

    margin: 0;
    margin-right: 2%;
    margin-bottom: 2%;

    &:nth-child(2) {
      margin-right: 0;
    }
    &:nth-child(4) {
      margin-right: 0;
    }
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 47.5%;
    height: 180px;
  }
`;
const IconBox = styled.div`
  width: 63px;

  display: inline-block;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 44px;
  }
`;
const Desc = styled.p`
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.78;
  letter-spacing: -0.72px;
  color: #5a5a5a;

  margin-top: 54px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
    line-height: 1.4;

    margin-top: 18px;
  }
`;
const Strong = styled.strong`
  font-weight: 700;
`;
// <<<

const MainNeeds = () => {
  return (
    <Container>
      <SubTitle>Why need BRIQUE Analytics?</SubTitle>
      <Desktop>
        <Title>
          <Bold>BRIQUE Analytics</Bold>가 필요한 이유는?
        </Title>
      </Desktop>
      <Mobile>
        <Title>
          <Bold>BRIQUE Analytics</Bold>가<br /> 필요한 이유는?
        </Title>
      </Mobile>

      <BoxArea>
        <NodeBox margin="0 30px 0 0">
          <IconBox>
            <img src={IconAlgorithm} alt="icon" style={{ width: "100%" }} />
          </IconBox>
          {/* Desktop */}
          <Desktop>
            <Desc>
              알고리즘을 개발할 지식이
              <br />
              없어도 <Strong>습득하면서 분석 가능</Strong>
            </Desc>
          </Desktop>
          {/* Mobile */}
          <Mobile>
            <Desc>
              알고리즘을 개발할
              <br /> 지식이 없어도
              <br /> <Strong>습득하면서 분석 가능</Strong>
            </Desc>
          </Mobile>
        </NodeBox>
        <NodeBox margin="83px 30px 0 0">
          <IconBox>
            <img src={IconPython} alt="icon" style={{ width: "100%" }} />
          </IconBox>
          {/* Desktop */}
          <Desktop>
            <Desc>
              <Strong>이종언어</Strong>로 개발 된 분석
              <br />
              알고리즘을 통합 및 관리 가능
            </Desc>
          </Desktop>
          {/* Mobile */}
          <Mobile>
            <Desc>
              <Strong>이종언어</Strong>로 개발 된<br /> 분석 알고리즘을
              <br /> 통합 및 관리 가능
            </Desc>
          </Mobile>
        </NodeBox>
        <NodeBox margin="0 30px 0 0">
          <IconBox>
            <img src={IconScenario} alt="icon" style={{ width: "100%" }} />
          </IconBox>
          {/* Desktop */}
          <Desktop>
            <Desc>
              <Strong>분석 시나리오</Strong>를 바로 구현 및<br />
              검증할 수 있는 온라인 환경 가능
            </Desc>
          </Desktop>
          {/* Mobile */}
          <Mobile>
            <Desc>
              <Strong>분석 시나리오</Strong>를 바로
              <br /> 구현 및 검증할 수 있는
              <br /> 온라인 환경 가능
            </Desc>
          </Mobile>
        </NodeBox>
        <NodeBox margin="83px 30px 0 0">
          <IconBox>
            <img src={IconCloud} alt="icon" style={{ width: "100%" }} />
          </IconBox>
          {/* Desktop */}
          <Desktop>
            <Desc>
              검증 된 분석 시나리오를
              <br />
              바로 <Strong>서비스 컨텐츠와 연계</Strong> 가능
            </Desc>
          </Desktop>
          {/* Mobile */}
          <Mobile>
            <Desc>
              검증 된 분석 시나리오를
              <br />
              바로{" "}
              <Strong>
                서비스 컨텐츠와
                <br /> 연계
              </Strong>{" "}
              가능
            </Desc>
          </Mobile>
        </NodeBox>
      </BoxArea>
    </Container>
  );
};

export default MainNeeds;
