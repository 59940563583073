import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import "antd/dist/antd.css";
import "swiper/swiper-bundle.css";
import "./assets/css/dropdown.css";
import "./assets/css/font.css";
import "./assets/css/swiper.css";

const GlobalStyle = createGlobalStyle`
  ${reset}

  /*
  * [Roboto]
  * light: 300, regular: 400, medium: 500, bold: 700, black: 900
  * [NotoSans]
  * regular: 400, bold: 700
  * [NotoSansKR]
  * regular: 400, medium: 500, bold: 700, balck: 900
  */

  html, body, #root {
    width: 100%;
    height: 100%;
    font-family: 'Noto Sans KR', 'Noto Sans', 'Roboto', 'galano-bold', sans-serif;
    font-size: 12px;
    color: #313336;
  } 
  span {
    display: inline-block;
  }
  a {
    text-decoration: none;
    color: #f6f7f8;
  }

  /* Scroll */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(163, 173, 193, .7);
    opacity: 0.7;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(163, 173, 193, 1);
  }
`;

export default GlobalStyle;
