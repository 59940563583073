import React, { useCallback } from "react";

import { errorBoundary } from "../../../hocs";

import MainPagePresenter from "./main-page.presenter";

let MainPage: Function = () => {
  const onClickTopIcon = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return <MainPagePresenter onClickTopIcon={onClickTopIcon} />;
};

MainPage = errorBoundary(MainPage);

export default MainPage;
