import { BehaviorSubject } from "rxjs";

const makeService = () => {
  const currentMenuId$ = new BehaviorSubject(null);

  currentMenuId$.asObservable();

  /**
   * @param id : 클릭된 '메뉴'의 아이디(메인,서브 포함)
   */
  const currentMenuId = (id: any) => currentMenuId$.next(id);

  return {
    currentMenuId$,

    currentMenuId,
  };
};

export const services = makeService();
