// 에러가 전체 애플리케이션을 중단시키지 않게 하기 위함
// 하위 컴포넌트 트리 어디든 자바스크립트 에러를 기록하며, 깨진 컴포넌트 대신 폴백 UI를 보여주는 react컴포넌트.

import React, { Component } from "react";

const errorBoundary = (Comp) =>
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { error: false };

      this.resetError = this.resetError.bind(this);
    }

    render() {
      const {
        props,
        state: { error },

        resetError,
      } = this;

      return error ? (
        <div onReset={resetError}>Something broken!</div>
      ) : (
        <Comp {...props} />
      );
    }

    componentDidCatch(error) {
      this.setState({ error });
    }

    resetError() {
      this.setState({ error: false });
    }
  };

export { errorBoundary };
