import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import { Desktop, Mobile } from "../../../consts";

// background image
import BGImg from "../../../assets/img/img-manual-bg.png";
// icons
import IconGettingstartedBlue from "../../../assets/img/ic-gettingstarted-blue.png";
import IconResourceBlue from "../../../assets/img/ic-resource-blue.png";
import IconLibraryBlue from "../../../assets/img/ic-library-blue.png";
import IconWorkflowBlue from "../../../assets/img/ic-workflow-blue.png";
import IconGettingstartedWhite from "../../../assets/img/ic-gettingstarted-white.png";
import IconResourceWhite from "../../../assets/img/ic-resource-white.png";
import IconLibraryWhite from "../../../assets/img/ic-library-white.png";
import IconWorkflowWhite from "../../../assets/img/ic-workflow-white.png";

import { ButtonWhite } from "../../atoms/button/button";

SwiperCore.use([Navigation, Pagination]);

const Container = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  padding-top: 72px;
  padding-bottom: 135px;

  background: url(${BGImg}) no-repeat center/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;
  padding: 0 20px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> box
const BoxArea = styled.div`
  display: flex;
  align-items: center;

  margin-top: 78px;
  margin-bottom: 100px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    /* width: 570px; */

    flex-flow: wrap;

    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100%;

    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0 20px;
  }
`;
const Box = styled.div`
  width: 270px;
  height: 313px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 5px solid #5185ff;
  background-color: #fff;
  box-shadow: 0px 2px 5px 4px rgba(194, 194, 194, 0.3);

  margin-right: 30px;

  &:nth-child(4) {
    margin-right: 0;
  }

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 49%;
    margin-bottom: 2%;
    margin-right: 2%;

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 49%;
    height: 195px;

    margin-right: 0;
    margin-bottom: 2%;

    &:nth-child(1) {
      margin-right: 2%;
    }
    &:nth-child(3) {
      margin-right: 2%;
    }
  }
`;
const BoxTitle = styled.div`
  font-family: "Noto Sans KR";
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.88px;

  color: #5185ff;

  margin-bottom: 20px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 16px;
  }
`;
const BoxDesc = styled.div`
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: -0.64px;

  margin-bottom: 40px;

  color: #9b9b9b;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;

    margin-bottom: 20px;
  }
`;
// <<<
// >>> node
const NodeArea = styled.div`
  display: flex;

  position: relative;

  margin-top: 84px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 760px;

    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100%;

    margin-top: 20px;
    padding: 0 20px;
  }
`;
type NodeStyleProps = {
  margin?: string;
  active: boolean;
};
const Node = styled.div<NodeStyleProps>`
  width: 230px;
  height: 200px;

  border-top: 5px solid #5185ff;
  background-color: ${(props) => (props.active ? "#5185ff" : "#fff")};
  box-shadow: 0px 2px 5px 4px rgba(194, 194, 194, 0.3);

  margin: ${(props) => props.margin};

  /* cursor: pointer; */
  z-index: 10;
  user-select: none;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100%;
  }
`;
type CircleStyleProps = {
  top: string;
  left: string;
};
const Circle = styled.div<CircleStyleProps>`
  width: 10px;
  height: 10px;

  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};

  background-color: #5185ff;
  border-radius: 50%;

  z-index: 20;
`;
type NodeTitleStyleProps = {
  active: boolean;
};
const NodeTitle = styled.div<NodeTitleStyleProps>`
  font-family: "Noto Sans KR";
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => (props.active ? "#fff" : "#5185ff")};
  line-height: 1.11;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
type IconBoxStyleProps = {
  margin: string;
};
const IconBox = styled.div<IconBoxStyleProps>`
  margin: ${(props) => props.margin};
`;
const Black = styled.span`
  font-weight: 900;

  margin-bottom: 8px;
`;
// <<<

type ManualProps = {
  activeId: string;
  onClickNode: Function;
  onClickGoGithub: Function;
  onClickDownload: Function;
};
const MainManual = ({
  activeId,
  onClickNode,
  onClickGoGithub,
  onClickDownload,
}: ManualProps) => {
  const under1200 = useMediaQuery({ query: "(max-width: 1200px)" });

  return (
    <Container className="manual">
      {/* 매뉴얼 다운로드 */}
      <SubTitle>BRIQUE Analytics Manual</SubTitle>

      <Title>
        <Bold>BRIQUE Analytics</Bold> 매뉴얼 다운로드
      </Title>
      <BoxArea>
        {/* 1. 기본기능 */}
        <Box>
          <BoxTitle>기본기능</BoxTitle>
          <BoxDesc>
            BA의 기본적인 기능에
            <br />
            관한 매뉴얼 입니다.
          </BoxDesc>
          <Desktop>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              margin="0 0 5px 0"
              fontSize="16px"
              onClick={() => onClickDownload({ id: "01.기본기능" })}
            >
              Download
            </ButtonWhite>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              fontSize="16px"
              onClick={() => onClickGoGithub({ id: "기본기능" })}
            >
              바로가기
            </ButtonWhite>
          </Desktop>
          <Mobile>
            <ButtonWhite
              width="120px"
              heigth="40px"
              lineHeight="40px"
              fontSize="14px"
              onClick={() => onClickGoGithub({ id: "기본기능" })}
            >
              바로가기
            </ButtonWhite>
          </Mobile>
        </Box>

        {/* 2. 작성방법 */}
        <Box>
          <BoxTitle>작성방법</BoxTitle>
          <BoxDesc>
            BA의 작성방법에 관한
            <br />
            매뉴얼 입니다.
          </BoxDesc>
          <Desktop>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              margin="0 0 5px 0"
              fontSize="16px"
              onClick={() => onClickDownload({ id: "02.작성방법" })}
            >
              Download
            </ButtonWhite>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              fontSize="16px"
              onClick={() => onClickGoGithub({ id: "작성방법" })}
            >
              바로가기
            </ButtonWhite>
          </Desktop>

          <Mobile>
            <ButtonWhite
              width="120px"
              heigth="40px"
              lineHeight="40px"
              fontSize="14px"
              onClick={() => onClickGoGithub({ id: "기본기능" })}
            >
              바로가기
            </ButtonWhite>
          </Mobile>
        </Box>

        {/* 3. 기능활용 */}
        <Box>
          <BoxTitle>기능활용</BoxTitle>
          <BoxDesc>
            BA의 기능활용에 관한
            <br />
            매뉴얼 입니다.
          </BoxDesc>
          {/* <Popover
            content={<PopoverText>준비중입니다.</PopoverText>}
            placement="right"
          > */}
          <Desktop>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              margin="0 0 5px 0"
              fontSize="16px"
              onClick={() => onClickDownload({ id: "03.기능활용" })}
            >
              Download
            </ButtonWhite>{" "}
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              fontSize="16px"
              onClick={() => onClickGoGithub({ id: "기능활용" })}
            >
              바로가기
            </ButtonWhite>
          </Desktop>{" "}
          {/* </Popover> */}
          <Mobile>
            <ButtonWhite
              width="120px"
              heigth="40px"
              lineHeight="40px"
              fontSize="14px"
              onClick={() => onClickGoGithub({ id: "기본기능" })}
            >
              바로가기
            </ButtonWhite>
          </Mobile>
        </Box>

        {/* 4. 사용툴팁 */}
        <Box>
          <BoxTitle>사용툴팁</BoxTitle>
          <BoxDesc>
            BA의 사용툴팁에 관한
            <br />
            매뉴얼 입니다.
          </BoxDesc>
          <Desktop>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              margin="0 0 5px 0"
              fontSize="16px"
              onClick={() => onClickDownload({ id: "04.사용툴팁" })}
            >
              Download
            </ButtonWhite>
            <ButtonWhite
              width="150px"
              heigth="45px"
              lineHeight="45px"
              fontSize="16px"
              onClick={() => onClickGoGithub({ id: "사용툴팁" })}
            >
              바로가기
            </ButtonWhite>
          </Desktop>
          <Mobile>
            <ButtonWhite
              width="120px"
              heigth="40px"
              lineHeight="40px"
              fontSize="14px"
              onClick={() => onClickGoGithub({ id: "기본기능" })}
            >
              바로가기
            </ButtonWhite>
          </Mobile>
        </Box>
      </BoxArea>

      {/* 튜토리얼 */}
      <Desktop>
        <Title>
          <Bold>BRIQUE Analytics</Bold> 쉽게 따라하는 튜토리얼
        </Title>
      </Desktop>
      <Mobile>
        <Title>
          <Bold>BRIQUE Analytics</Bold>
          <br /> 쉽게 따라하는 튜토리얼
        </Title>
      </Mobile>
      {under1200 ? (
        <NodeArea>
          <Swiper
            tag="section"
            wrapperTag="ul"
            pagination
            spaceBetween={10}
            slidesPerView={2}
            slidesPerGroup={2}
          >
            <SwiperSlide tag="li">
              {/* getting started */}
              <Node
                // margin="0 84px 0 0"
                active={activeId === "gettingstarted" ? true : false}
                onClick={(event) =>
                  onClickNode(event, { id: "gettingstarted" })
                }
              >
                <IconBox margin="38px 0 20px 0">
                  <img
                    src={
                      activeId === "gettingstarted"
                        ? IconGettingstartedWhite
                        : IconGettingstartedBlue
                      // IconGettingstartedBlue
                    }
                    alt="icon"
                  />
                </IconBox>
                <NodeTitle
                  active={activeId === "gettingstarted" ? true : false}
                >
                  <Black>Getting Started</Black>
                  <br />
                  따라하기
                </NodeTitle>
              </Node>
            </SwiperSlide>
            <SwiperSlide tag="li">
              {/* resource */}
              <Node
                // margin="57px 84px 0 0"
                active={activeId === "resource" ? true : false}
                onClick={(event) => onClickNode(event, { id: "resource" })}
              >
                <IconBox margin="46px 0 26px 0">
                  <img
                    src={
                      activeId === "resource"
                        ? IconResourceWhite
                        : IconResourceBlue
                      // IconResourceBlue
                    }
                    alt="icon"
                  />
                </IconBox>
                <NodeTitle active={activeId === "resource" ? true : false}>
                  <Black>Resource</Black> 작성하기
                </NodeTitle>
              </Node>
            </SwiperSlide>
            <SwiperSlide tag="li">
              {/* library */}
              <Node
                active={activeId === "library" ? true : false}
                onClick={(event) => onClickNode(event, { id: "library" })}
              >
                <IconBox margin="46px 0 26px 0">
                  <img
                    src={
                      activeId === "library"
                        ? IconLibraryWhite
                        : IconLibraryBlue
                      // IconLibraryBlue
                    }
                    alt="icon"
                  />
                </IconBox>
                <NodeTitle active={activeId === "library" ? true : false}>
                  <Black>Library</Black> 작성하기
                </NodeTitle>
              </Node>
            </SwiperSlide>
            <SwiperSlide tag="li">
              {/* workflow */}
              <Node
                active={activeId === "workflow" ? true : false}
                onClick={(event) => onClickNode(event, { id: "workflow" })}
              >
                <IconBox margin="46px 0 26px 0">
                  <img
                    src={
                      activeId === "workflow"
                        ? IconWorkflowWhite
                        : IconWorkflowBlue
                      // IconWorkflowBlue
                    }
                    alt="icon"
                  />
                </IconBox>
                <NodeTitle active={activeId === "workflow" ? true : false}>
                  <Black>Workflow</Black> 작성하기
                </NodeTitle>
              </Node>
            </SwiperSlide>
          </Swiper>
        </NodeArea>
      ) : (
        <NodeArea>
          {/* getting started */}
          {/* <Popover
          content={<PopoverText>준비중입니다.</PopoverText>}
          placement="bottom"
        > */}
          <Node
            margin="0 84px 0 0"
            active={activeId === "gettingstarted" ? true : false}
            onClick={(event) => onClickNode(event, { id: "gettingstarted" })}
          >
            <IconBox margin="38px 0 20px 0">
              <img
                src={
                  activeId === "gettingstarted"
                    ? IconGettingstartedWhite
                    : IconGettingstartedBlue
                  // IconGettingstartedBlue
                }
                alt="icon"
              />
            </IconBox>
            <NodeTitle active={activeId === "gettingstarted" ? true : false}>
              <Black>Getting Started</Black>
              <br />
              따라하기
            </NodeTitle>
          </Node>
          {/* </Popover> */}
          <Circle top="86px" left="226px" />
          <Circle top="126px" left="308px" />

          {/* resource */}
          <Node
            margin="57px 84px 0 0"
            active={activeId === "resource" ? true : false}
            onClick={(event) => onClickNode(event, { id: "resource" })}
          >
            <IconBox margin="46px 0 26px 0">
              <img
                src={
                  activeId === "resource" ? IconResourceWhite : IconResourceBlue
                  // IconResourceBlue
                }
                alt="icon"
              />
            </IconBox>
            <NodeTitle active={activeId === "resource" ? true : false}>
              <Black>Resource</Black> 작성하기
            </NodeTitle>
          </Node>
          <Circle top="124px" left="540px" />
          <Circle top="65px" left="622px" />

          {/* library */}
          <Node
            margin="0 84px 0 0"
            active={activeId === "library" ? true : false}
            onClick={(event) => onClickNode(event, { id: "library" })}
          >
            <IconBox margin="46px 0 26px 0">
              <img
                src={
                  activeId === "library" ? IconLibraryWhite : IconLibraryBlue
                  // IconLibraryBlue
                }
                alt="icon"
              />
            </IconBox>
            <NodeTitle active={activeId === "library" ? true : false}>
              <Black>Library</Black> 작성하기
            </NodeTitle>
          </Node>
          <Circle top="65px" left="854px" />
          <Circle top="124px" left="936px" />

          {/* workflow */}
          <Node
            margin="57px 0 0 0"
            active={activeId === "workflow" ? true : false}
            onClick={(event) => onClickNode(event, { id: "workflow" })}
          >
            <IconBox margin="46px 0 26px 0">
              <img
                src={
                  activeId === "workflow" ? IconWorkflowWhite : IconWorkflowBlue
                  // IconWorkflowBlue
                }
                alt="icon"
              />
            </IconBox>
            <NodeTitle active={activeId === "workflow" ? true : false}>
              <Black>Workflow</Black> 작성하기
            </NodeTitle>
          </Node>

          {/* path */}
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              userSelect: "none",
            }}
          >
            <path
              d={`M230 90 Q 250 84, 270 110 T 312 130`}
              fill="transparent"
              stroke="#5185ff"
            />
            <path
              d={`M545 130 Q 574 126, 590 98 T 627 70`}
              fill="transparent"
              stroke="#5185ff"
            />
            <path
              d={`M858 70 Q 888 70, 906 100 T 941 130`}
              fill="transparent"
              stroke="#5185ff"
            />
          </svg>
        </NodeArea>
      )}
    </Container>
  );
};

export default MainManual;
