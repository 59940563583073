import React, { useState, useCallback, useEffect } from "react";
import { filter } from "rxjs/operators";

import { errorBoundary } from "../../../hocs";
import { EXAMPLE } from "../../../consts";
import { services } from "../../../services";

import MainExamplePresenter from "./main-example.presenter";

let MainExample: Function = () => {
  const [items, changeitems] = useState(new Map());

  // init set
  useEffect(() => {
    const init = new Map();
    EXAMPLE.map((item) => init.set(item.id, item));
    changeitems(init);
  }, []);

  // 상단 메뉴가 클릭 될 경우, 아코디언 모두 닫기
  useEffect(() => {
    const subscription = services.currentMenuId$
      .pipe(filter((id) => id !== null))
      .subscribe((id) => {
        const set = new Map(items);

        for (const [key, item] of set) {
          set.set(key, { ...item, open: false });
        }
        changeitems(set);
        services.currentMenuId(null);
      });
    return () => subscription.unsubscribe();
  }, [items]);

  // 아코디언 메뉴 클릭 시
  const onClickItem = useCallback(
    (event, { id }) => {
      const set = new Map(items);
      for (const [key, item] of items) {
        if (key === id) {
          set.set(key, { ...item, open: !items.get(id).open });
        } else {
          set.set(key, { ...item, open: false });
        }
      }
      changeitems(set);
    },
    [items]
  );

  return <MainExamplePresenter items={items} onClickItem={onClickItem} />;
};

MainExample = errorBoundary(MainExample);

export default MainExample;
