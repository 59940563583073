import React from "react";
import styled from "styled-components";

import BGImg from "../../../assets/img/channel-bg.png";
import IconTistory from "../../../assets/img/ic-tistory.png";
import IconYoutube from "../../../assets/img/ic-youtube.png";
import ImgTistory from "../../../assets/img/img-tistory.png";
import ImgYoutube from "../../../assets/img/img-youtube.png";
import IconArrow from "../../../assets/img/ic-arrow.png";

const CAROUSEL = [
  {
    id: "YOUTUBE",
    img: ImgYoutube,
    icon: IconYoutube,
  },
  {
    id: "TISTORY",
    img: ImgTistory,
    icon: IconTistory,
  },
];

const Container = styled.div`
  height: auto;
  width: 100%;

  text-align: center;
  padding-top: 73px;
  padding-bottom: 138px;

  background: url(${BGImg}) no-repeat bottom/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    background: #dfe8f2;

    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> Carousel
const CarouselArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 83px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-top: 20px;
    padding: 0 20px;
  }
`;
type CarouselProps = {
  margin: boolean;
};
const Carousel = styled.div<CarouselProps>`
  position: relative;

  margin-right: ${(props) => (props.margin ? "52px" : 0)};

  cursor: pointer;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 50%;

    margin-right: ${(props) => (props.margin ? "20px" : 0)};
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-right: ${(props) => (props.margin ? "2%" : 0)};
  }
`;
const IconStyle = styled.div`
  width: 30%;

  position: absolute;
  top: 45%;
  left: 35%;
  transform: translateX(-45%, -50%);

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 40%;
    left: 30%;
  }
`;
type ArrowAreaProps = {
  position: string;
};
const ArrowArea = styled.div<ArrowAreaProps>`
  height: 37px;

  margin: ${(props) =>
    props.position === "left" ? "0 68px 0 0" : "0 0 0 68px"};

  transform: ${(props) => props.position === "right" && "rotate(180deg)"};

  cursor: pointer;
`;
// <<<

type MainChennelProps = {
  onClickChennel: any;
};
const MainChennel = ({ onClickChennel }: MainChennelProps) => {
  return (
    <Container>
      <SubTitle>BRIQUE Analytics Channel</SubTitle>
      <Title>
        <Bold>BRIQUE Analytics</Bold>의 채널
      </Title>

      <CarouselArea>
        {CAROUSEL.length > 2 ? (
          <ArrowArea position="left">
            <img src={IconArrow} alt="left arrow" />
          </ArrowArea>
        ) : null}
        {CAROUSEL.map((item, idx) => (
          <Carousel
            key={item.id}
            margin={idx === 0 ? true : false}
            onClick={(event) => onClickChennel(event, { id: item.id })}
          >
            <img
              src={item.img}
              alt={`${item.id} 이미지`}
              style={{ width: "100%" }}
            />
            <IconStyle>
              <img
                src={item.icon}
                alt={`${item.id} 아이콘`}
                style={{ width: "100%" }}
              />
            </IconStyle>
          </Carousel>
        ))}
        {CAROUSEL.length > 2 ? (
          <ArrowArea position="right">
            <img src={IconArrow} alt="right arrow" />
          </ArrowArea>
        ) : null}
      </CarouselArea>
    </Container>
  );
};

export default MainChennel;
