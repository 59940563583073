import React, { useCallback } from "react";

import { errorBoundary } from "../../../hocs";

import MainChennelPresenter from "./main-chennel.presenter";

let MainChennel: Function = () => {
  const onClickChennel = useCallback((event, { id }) => {
    if (id === "YOUTUBE") {
      window.open(
        "https://www.youtube.com/channel/UCyrs5eAl9rJjpWCYcayQVyQ",
        "_blank"
      );
    }
    if (id === "TISTORY") {
      window.open("https://brique-analytics.tistory.com/", "_blank");
    }
  }, []);

  return <MainChennelPresenter onClickChennel={onClickChennel} />;
};

MainChennel = errorBoundary(MainChennel);

export default MainChennel;
