const { API_HOST } = (() => {
  if (!window || !window.__BA_CONFIG__)
    return {
      API_HOST: "localhost",
    };

  return {
    API_HOST: window.__BA_CONFIG__.API_HOST,
  };
})();

export { API_HOST };
