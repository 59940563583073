import styled from "styled-components";

type AdditionalProps = {
  margin: string;
};
const ButtonWhite = styled.div.attrs<AdditionalProps>(({ margin }) => ({
  style: {
    margin,
  },
}))`
  width: 150px;
  height: 45px;

  font-family: "Noto Sans KR", "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  color: #5185ff;

  border-radius: 24px;

  text-align: center;

  background-color: #fff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;

  user-select: none;

  margin: 40px 0 5px 60px;
`;

export default ButtonWhite;
