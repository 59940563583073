import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import Logo from "../../../assets/img/logo-footer.svg";
import IconTistory from "../../../assets/img/ic-tistory-link.png";
import IconWeb from "../../../assets/img/ic-web-link.png";
import IconYoutube from "../../../assets/img/ic-youtube-link.png";

const Container = styled.div`
  height: auto;
  width: 100%;

  background-color: #133c91;

  text-align: center;
  padding-top: 114px;
  padding-bottom: 54px;
  margin-top: -2px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding-top: 60px;
    padding-bottom: 40px;
  }
`;
const LogoArea = styled.div`
  width: 194px;

  margin: auto;
  margin-bottom: 49px;
`;
const Information = styled.p`
  font-family: "Roboto";
  font-size: 20px;
  line-height: 1.35;
  color: #99acd4;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    padding: 0 80px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;

    padding: 0 20px;
  }
`;
const LinkArea = styled.div`
  margin-top: 34px;
`;
const Line = styled.div`
  width: 1170px;
  height: 1px;

  background-color: rgba(225, 225, 225, 0.2);

  margin: auto;
  margin-top: 29px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
`;
const CopyText = styled.p`
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 16px;
  color: #99acd4;

  margin-top: 49px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 12px;

    margin-top: 20px;
  }
`;

const MainBottom = () => {
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 576px)",
  });

  return (
    <Container>
      <LogoArea>
        <img src={Logo} alt="Logo" style={{ width: "100%" }} />
      </LogoArea>
      <Information>
        Tel. +82-31-714-2900, Fax. +82-31-713-2904
        <br />
        230, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of
        Korea
      </Information>
      <LinkArea>
        <a href="http://brique.co.kr" target="_blank" rel="noopener noreferrer">
          <img
            src={IconWeb}
            alt="go web"
            style={{
              width: isMobile ? "38px" : "52px",
              marginRight: isMobile ? "14px" : "28px",
              cursor: "pointer",
            }}
          />
        </a>
        <a href="https://brique-analytics.tistory.com/" target="_bland">
          <img
            src={IconTistory}
            alt="go tistory"
            style={{
              width: isMobile ? "38px" : "52px",
              marginRight: isMobile ? "14px" : "28px",
              cursor: "pointer",
            }}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCyrs5eAl9rJjpWCYcayQVyQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={IconYoutube}
            alt="go youtube"
            style={{ width: isMobile ? "38px" : "52px", cursor: "pointer" }}
          />
        </a>
      </LinkArea>
      <Line />
      <CopyText>© 2020 BRIQUE Analytics. All rights reserved.</CopyText>
    </Container>
  );
};

export default MainBottom;
