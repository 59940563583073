import React, { useCallback } from "react";

import { errorBoundary } from "../../../hocs";

import MainDownloadPresenter from "./main-download.presenter";

let MainDownload: Function = () => {
  // 설치 가이드 클릭 시, pdf파일들 zip으로 다운로드
  const onClickDownloadZip = useCallback(
    () =>
      (window.location.href =
        "https://ba.brique.kr/file/04-02-설치매뉴얼_01_StandAlone_Docker.pdf"),
    []
  );

  // 다운로드 클릭 시, url로 바로 다운
  const onClickDownload = useCallback(
    () =>
      (window.location.href =
        "https://ba.brique.kr/file/installer/v210r1/ba-v210r1-win64-installer.zip"),
    // Linux deb 패키지 url: "https://ba.brique.kr/file/installer/v210r1/ba_2.1.0-r1-1_amd64.deb",
    // Linux rpm 패키지 url: "https://ba.brique.kr/file/installer/v210r1/ba-2.1.0-r1.noarch.rpm"
    []
  );

  // Github Link 클릭 시 깃랩주소로 이동
  const onClickGithub = useCallback(
    () =>
      window.open(
        "https://gitlab.brique.kr/brique-analytics/manual/-/tree/v2.2.0-r1/01-%EB%A7%A4%EB%89%B4%EC%96%BC/01-%EC%84%A4%EC%B9%98%EB%A7%A4%EB%89%B4%EC%96%BC",
        "_blank"
      ),
    []
  );

  return (
    <MainDownloadPresenter
      onClickDownloadZip={onClickDownloadZip}
      onClickDownload={onClickDownload}
      onClickGithub={onClickGithub}
    />
  );
};

MainDownload = errorBoundary(MainDownload);

export default MainDownload;
