import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 721px)" });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  // const isMobile = useMediaQuery({ minWidth: 720 });
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  return isMobile ? children : null;
};

export { Desktop, Mobile };
