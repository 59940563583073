import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { services } from "../../../services";

import MainBottom from "../../molecules/main-bottom/main-bottom";

import MainTitle from "../../organisms/main-title/main-title";
import MainAbout from "../../organisms/main-about/main-about";
import MainNeeds from "../../organisms/main-needs/main-needs";
import MainEffect from "../../organisms/main-effect/main-effect";
import MainFAQ from "../../organisms/main-faq/main-faq";
import MainChennel from "../../organisms/main-chennel/main-chennel";
import MainContact from "../../organisms/main-contact/main-contact";
import MainExample from "../../organisms/main-example/main-example";
import MainManual from "../../organisms/main-manual/main-manual";
import MainDownload from "../../organisms/main-download/main-download";
import { filter } from "rxjs/operators";

const TopIcon = styled.div`
  width: 60px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;

  position: fixed;
  bottom: 40px;
  right: 40px;

  background-color: #383838;
  color: #fff;
  border-radius: 50%;

  cursor: pointer;
  z-index: 30;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 40px;
    height: 40px;

    bottom: 20px;
    right: 20px;

    font-size: 12px;
    opacity: 0.7;
  }
`;

type MainPageProps = {
  onClickTopIcon: any;
};
const MainPage = ({ onClickTopIcon }: MainPageProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const aboutY = useRef<any>();
  const manualY = useRef<any>();
  const chennelY = useRef<any>();
  const downloadY = useRef<any>();
  const faqY = useRef<any>();
  const exampleY = useRef<any>();

  const [clickedMenuId, changeClickedMenuId] = useState<any>(null);

  useEffect(() => {
    const subscription = services.currentMenuId$
      .pipe(filter((id) => id != null))
      .subscribe((id) => changeClickedMenuId(id));

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (clickedMenuId) {
      if (clickedMenuId === "About")
        window.scrollTo({ top: aboutY.current.offsetTop, behavior: "smooth" });
      if (clickedMenuId === "FAQ")
        window.scrollTo({ top: faqY.current.offsetTop, behavior: "smooth" });
      if (clickedMenuId === "활용사례")
        window.scrollTo({
          top: exampleY.current.offsetTop,
          behavior: "smooth",
        });
      if (
        ["기본기능", "작성방법", "기능활용", "사용툴팁"].includes(clickedMenuId)
      )
        window.scrollTo({ top: manualY.current.offsetTop, behavior: "smooth" });
      // 컴포넌트 분리 안되어있어 정확한 위치를 잡지 못함
      if (clickedMenuId === "튜토리얼")
        window.scrollTo({
          top: isMobile
            ? manualY.current.offsetTop + 520
            : manualY.current.offsetTop + 650,
          behavior: "smooth",
        });
      if (clickedMenuId === "Link")
        window.scrollTo({
          top: chennelY.current.offsetTop,
          behavior: "smooth",
        });
      if (clickedMenuId === "Download")
        window.scrollTo({
          top: downloadY.current.offsetTop,
          behavior: "smooth",
        });
    }
  }, [clickedMenuId, isMobile]);

  return (
    <>
      <MainTitle />
      <div ref={aboutY}>
        <MainAbout />
      </div>
      <MainNeeds />
      <MainEffect />
      <div ref={manualY}>
        <MainManual />
      </div>
      <div ref={exampleY}>
        <MainExample />
      </div>
      <div ref={downloadY}>
        <MainDownload />
      </div>
      <div ref={faqY}>
        <MainFAQ />
      </div>
      <div ref={chennelY}>
        <MainChennel />
      </div>
      <MainContact />
      <MainBottom />
      <TopIcon onClick={onClickTopIcon}>Top</TopIcon>
    </>
  );
};

export default MainPage;
