import { errorBoundary } from "../../../hocs";

import ButtonBlackPresenter from "./button-black.presenter";
import ButtonWhitePresenter from "./button-white.presenter";
import ButtonDisablePresenter from "./button-disable.presenter";

const ButtonBlack = errorBoundary(ButtonBlackPresenter);
const ButtonWhite = errorBoundary(ButtonWhitePresenter);
const ButtonDisable = errorBoundary(ButtonDisablePresenter);

export { ButtonBlack, ButtonWhite, ButtonDisable };
