import React from "react";
import styled from "styled-components";

import Logo from "../../../assets/img/logo-main.svg";
import IntroVideo from "../../../assets/video/introduce-ba.gif";

import { Desktop, Mobile } from "../../../consts";

import MainHeader from "../../molecules/main-header/main-header";

import { ButtonBlack, ButtonWhite } from "../../atoms/button/button";

const Container = styled.div`
  height: auto;

  padding: 110px 0;

  background-color: #95b3e6;

  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 80px 0 40px;
  }
`;
const Contents = styled.div`
  width: 1172px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto;
  text-align: center;

  @media only screen and (max-width: 1172px) {
    width: 100%;
  }
`;
const LogoArea = styled.div`
  width: 228px;

  margin: 0 auto 20px;
`;
// >>> text
const TextArea = styled.div`
  margin-bottom: 46px;

  @media only screen and (min-width: 320px) and (max-width: 720px) {
    margin-bottom: 26px;
  }
`;
const SubText = styled.div`
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.29;

  color: #e8e8e8;

  @media only screen and (max-width: 900px) {
    margin: auto;
    padding: 0 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;

    padding: 0 10px;
  }
`;
// >>> button
const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
  }
`;
// >>> video
const VideoArea = styled.div`
  width: 994px;

  margin-top: 79px;

  @media only screen and (max-width: 1172px) {
    width: 90%;
  }
  @media only screen and (min-width: 320px) and (max-width: 720px) {
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;
// <<<

type MainTitleProps = {
  onClickDownload: Function;
  onClickCatalog: Function;
};
const MainTitle = ({ onClickDownload, onClickCatalog }: MainTitleProps) => {
  return (
    <Container>
      <MainHeader />
      <Contents>
        <TextArea>
          <LogoArea>
            <img src={Logo} alt="logo" style={{ width: "100%" }} />
          </LogoArea>
          <Desktop>
            <SubText>
              BRIQUE Analytics is an integrated analysis platform that provides
              an easier
              <br />
              analysis environment and enables rapid development and system
              application.
            </SubText>
          </Desktop>

          <Mobile>
            <SubText>
              BRIQUE Analytics is an integrated analysis platform that provides
              an easier analysis environment and enables rapid development and
              system application.
            </SubText>
          </Mobile>
        </TextArea>

        {/* Mobile */}
        <Mobile>
          <VideoArea>
            <img src={IntroVideo} alt="" style={{ width: "100%" }} />
          </VideoArea>

          <ButtonArea>
            <ButtonBlack
              width="214px"
              height="48px"
              fontSize="18px"
              onClick={onClickDownload}
            >
              Get Download
            </ButtonBlack>
          </ButtonArea>
        </Mobile>

        {/* Desktop */}
        <Desktop>
          <ButtonArea>
            <ButtonBlack width="214px" height="48px" onClick={onClickDownload}>
              Get Download
            </ButtonBlack>
            <ButtonWhite
              width="214px"
              height="48px"
              lineHeight="50px"
              fontSize="20px"
              fontFamily="Roboto"
              borderRadius="50px"
              hoverColor="#5185ff"
              hoverBgColor="#fff"
              border="0"
              margin="0 0 0 25px"
              onClick={onClickCatalog}
            >
              Web Catalog
            </ButtonWhite>
          </ButtonArea>
          <VideoArea>
            <img src={IntroVideo} alt="" style={{ width: "100%" }} />
          </VideoArea>
        </Desktop>
      </Contents>
    </Container>
  );
};

export default MainTitle;
