export const EXAMPLE = [
  {
    id: 1,
    title: "알고리즘 검증",
    subTitle: "이상자재 탐지 알고리즘 검증",
    open: false,
  },
  {
    id: 2,
    title: "실시간 알고리즘 실행엔진",
    subTitle: "이미지 패턴기반 오류 검출 및 공정 제어",
    open: false,
  },
  {
    id: 3,
    title: "트랜드 분석도구",
    subTitle: "최적의 Settling조건을 찾기 위한 비교분석",
    open: false,
  },
  {
    id: 4,
    title: "이상감지 지원도구",
    subTitle: "H/W 이상여부 점검 자동화",
    open: false,
  },
];
