import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Desktop } from "../../../consts";

import BGImg from "../../../assets/img/download-bg.png";
import IconDownloadBlue from "../../../assets/img/ic-download-blue.png";
import IconDownloadWhite from "../../../assets/img/ic-download-white.png";
import IconLinkBlue from "../../../assets/img/ic-link-blue.png";
import IconLinkWhite from "../../../assets/img/ic-link-white.png";
import IconArrowBlue from "../../../assets/img/ic-arrow-blue.png";
import IconCheckRec from "../../../assets/img/ic-check-rec.png";

import { INSTALL_STANDALONE, INSTALL_ENTERPRISE } from "../../../consts";

import { ButtonWhite } from "../../atoms/button/button";

const Container = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  padding: 72px 80px 100px;

  background: url(${BGImg}) no-repeat bottom/cover;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 40px 20px 40px;
  }
`;
// >>> text
const SubTitle = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;

  color: #5185ff;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
  }
`;
const Title = styled.h2`
  font-family: "Noto Sans KR";
  font-size: 40px;
  line-height: 58px;
  color: #0f1a36;
  letter-spacing: -1.6;

  margin-top: 30px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 18px;
    line-height: 26px;

    margin-top: 8px;
  }
`;
const Bold = styled.b`
  font-family: "Roboto";
  font-weight: 800;
`;
// <<<
// >>> 설치 사양
const InfoArea = styled.div`
  width: 1170px;
  height: 840px;

  position: relative;

  border: 1px solid #b5b5b5;
  border-radius: 16px;

  margin-top: 85px;
  margin-bottom: 55px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: auto;

    padding: 50px 0;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    border-radius: 10px;

    margin-top: 40px;
    margin-bottom: 0;
    padding: 20px 0;
  }
`;
const InfoTitle = styled.div`
  width: 330px;

  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -165px;

  font-family: "Roboto";
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.96px;

  color: #5a5a5a;
  background-color: #dfe8f2;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100px;
    margin: 0;

    font-size: 18px;

    transform: translateX(-50%);
  }
`;
const LayoutBox = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentsBox = styled.div`
  display: flex;
  align-items: flex-start;

  margin-top: 20px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 100%;
  }
`;
// >>> stand alone
const StandAloneArea = styled.div`
  padding-left: 40px;
  padding-right: 127px;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 50%;

    padding: 0 20px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 0 10px;
  }
`;
const InstallTitleArea = styled.div`
  display: flex;
  align-items: center;
`;
const InstallTitle = styled.div`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 20px;
  color: #5587ff;

  padding-left: 11px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 16px;
  }
`;
const ContentsArea = styled.div`
  margin-top: 30px;
  margin-left: 22px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-left: 0;
  }
`;
const EnterpriseContentsArea = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 30px;
  margin-left: 22px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    margin-left: 0;
  }
`;
type ContentStyleProps = {
  marginRight?: string;
  marginBottom?: string;
};
const Content = styled.div<ContentStyleProps>`
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
`;
const ContentsTitleArea = styled.div`
  display: flex;
  align-items: center;
`;
const ContentsTitle = styled.div`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  color: #5a5a5a;

  text-align: left;
  padding-left: 6px;
  white-space: nowrap;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 14px;
    font-weight: 500;
  }
`;
const DescList = styled.ul`
  display: flex;
  flex-direction: column;

  margin-top: 11px;
  margin-left: 22px;
`;
const List = styled.li`
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;
const Circle = styled.div`
  width: 4px;
  height: 4px;

  border-radius: 2px;

  background-color: #828282;
`;
const DescText = styled.div`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #828282;

  text-align: left;
  padding-left: 8px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    font-size: 13px;
  }
`;

// >>> enterprise
const EnterpriseArea = styled.div`
  padding-left: 40px;

  border-left: 1px solid #b8b8b8;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 50%;

    padding: 0 20px;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    padding: 0 10px;
  }
`;
// <<<
// >>> button
const ButtonArea = styled.div`
  display: flex;
  justify-content: center;

  /* Under 1200px - Moblie */
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    /* justify-content: center; */

    margin-top: 10px;
  }
`;
const IconBox = styled.span`
  width: 20px;

  margin-left: 14px;

  /* Mobile */
  @media only screen and (min-width: 320px) and (max-width: 576px) {
    width: 12px;

    margin-left: 4px;
  }
`;
// >>>

type DownloadProps = {
  onClickDownloadZip: Function;
  onClickDownload: Function;
  onClickGithub: Function;
};
const MainDownload = ({
  onClickDownloadZip,
  onClickDownload,
  onClickGithub,
}: DownloadProps) => {
  const [hoverId, changeHoverId] = useState<string>("");

  // const smallDesktop = useMediaQuery({ query: "(max-width: 576px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  return (
    <Container>
      <SubTitle>BRIQUE Analytics Download</SubTitle>
      <Title>
        <Bold>BRIQUE Analytics</Bold> 다운로드
      </Title>
      {/* 설치 사양 */}
      <InfoArea>
        <InfoTitle>
          <Desktop>BRIQUE Analytics </Desktop>설치 최소 사양
        </InfoTitle>
        <LayoutBox>
          <ContentsBox>
            {/* Stand Alone 설치 사양 */}
            <StandAloneArea>
              <InstallTitleArea>
                <img
                  src={IconArrowBlue}
                  alt="arrow icon"
                  style={{ width: "12px", height: "8px" }}
                />
                <InstallTitle>Stand Alone</InstallTitle>
              </InstallTitleArea>
              {/* 설치 사양 항목 */}
              <ContentsArea>
                {INSTALL_STANDALONE.map((item) => (
                  <Content
                    key={item.key}
                    marginBottom={isMobile ? "16px" : "32px"}
                  >
                    <ContentsTitleArea>
                      <img src={IconCheckRec} alt="check icon" />
                      <ContentsTitle>{item.title}</ContentsTitle>
                    </ContentsTitleArea>
                    <DescList>
                      {item.desc.map((list) => (
                        <>
                          {list.title && (
                            <div style={{ margin: "4px 0 10px 0" }}>
                              <DescText style={{ fontWeight: 700 }}>
                                {list.title}
                              </DescText>
                            </div>
                          )}
                          <List key={list.id}>
                            <Circle />
                            <DescText>{list.text}</DescText>
                          </List>
                        </>
                      ))}
                    </DescList>
                  </Content>
                ))}
              </ContentsArea>
            </StandAloneArea>
            {/* Enterprise 설치 사양 */}
            <EnterpriseArea>
              <InstallTitleArea>
                <img
                  src={IconArrowBlue}
                  alt="arrow icon"
                  style={{ width: "12px", height: "8px" }}
                />
                <InstallTitle>Enterprise</InstallTitle>
              </InstallTitleArea>
              {/* 설치 사양 항목 */}
              <EnterpriseContentsArea>
                {INSTALL_ENTERPRISE.map((item) => (
                  <Content
                    key={item.key}
                    marginRight={
                      item.key === 1 ? "76px" : item.key === 3 ? "70px" : ""
                    }
                    marginBottom={
                      item.key === 1 && !isMobile
                        ? "32px"
                        : item.key === 2 && !isMobile
                        ? "60px"
                        : item.key === 6 && !isMobile
                        ? "0"
                        : item.key === 1 && isMobile
                        ? "4px"
                        : item.key === 2 && isMobile
                        ? "50px"
                        : "16px"
                    }
                  >
                    <ContentsTitleArea>
                      <img src={IconCheckRec} alt="check icon" />
                      <ContentsTitle>{item.title}</ContentsTitle>
                    </ContentsTitleArea>
                    <DescList>
                      {item.desc.map((list) => (
                        <List key={list.id}>
                          <Circle />
                          <DescText>{list.text}</DescText>
                        </List>
                      ))}
                    </DescList>
                  </Content>
                ))}
              </EnterpriseContentsArea>
            </EnterpriseArea>
          </ContentsBox>
        </LayoutBox>
      </InfoArea>

      {/* buttons */}
      <ButtonArea>
        <Desktop>
          <ButtonWhite
            width="289px"
            height={isMobile ? "40px" : "62px"}
            lineHeight={isMobile ? "40px" : "62px"}
            fontSize={isMobile ? "14px" : "20px"}
            borderRadius="50px"
            border="0"
            margin={isMobile ? "0 8px 0 0" : "0 17px 0 0"}
            onMouseOver={() => changeHoverId("설치가이드")}
            onMouseLeave={() => changeHoverId("")}
            onClick={onClickDownloadZip}
          >
            설치 가이드
            <IconBox>
              <img
                src={
                  hoverId === "설치가이드"
                    ? IconDownloadWhite
                    : IconDownloadBlue
                }
                alt="설치가이드 다운로드 버튼"
                style={{ width: "100%" }}
              />
            </IconBox>
          </ButtonWhite>
          {/* 버튼 삭제 2022/3/14 */}
          {/* <ButtonWhite
            width="289px"
            height={isMobile ? "40px" : "62px"}
            lineHeight={isMobile ? "40px" : "62px"}
            fontSize={isMobile ? "14px" : "20px"}
            borderRadius="50px"
            border="0"
            margin={isMobile ? "0 8px 0 0" : "0 17px 0 0"}
            onMouseOver={() => changeHoverId("다운로드")}
            onMouseLeave={() => changeHoverId("")}
            onClick={onClickDownload}
          >
            Download
            <IconBox>
              <img
                src={
                  hoverId === "다운로드" ? IconDownloadWhite : IconDownloadBlue
                }
                alt="다운로드 버튼"
                style={{ width: "100%" }}
              />
            </IconBox>
          </ButtonWhite> */}
        </Desktop>
        <ButtonWhite
          width={isMobile ? "140px" : "289px"}
          height={isMobile ? "40px" : "62px"}
          lineHeight={isMobile ? "40px" : "62px"}
          fontSize={isMobile ? "14px" : "20px"}
          borderRadius="50px"
          border="0"
          onMouseOver={() => changeHoverId("깃허브")}
          onMouseLeave={() => changeHoverId("")}
          onClick={onClickGithub}
        >
          Gitlab Link
          <IconBox>
            <img
              src={hoverId === "깃허브" ? IconLinkWhite : IconLinkBlue}
              alt="gitlab 페이지 열기"
              style={{ width: "100%" }}
            />
          </IconBox>
        </ButtonWhite>
      </ButtonArea>
    </Container>
  );
};

export default MainDownload;
